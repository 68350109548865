import './App.css';
import AppRoutes from "./routes/AppRoutes";
/* 
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
} */

const App = () =>{
  return (  <>
    <div className="main-container">
      <AppRoutes />
    </div>
  </>)
}

export default App;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  API_POST_PRODUCT, API_GET_PRODUCT } from '../services/CommonService'; //API_GET_LOGIN,

export const addNewProduct = createAsyncThunk('products/addNewProduct', async (newProduct) => {
  const response = await API_POST_PRODUCT(newProduct);
  debugger;
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error('Failed to add product');
  }
});

export const fetchProductOptions = createAsyncThunk('product/fetchProductOptions', async () => {
    const response = await API_GET_PRODUCT();
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch industry options');
    }
  });

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    productOptions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      })
      .addCase(addNewProduct.fulfilled, (state, action) => {
        state.productOptions.push(action.payload);
      });

    
  },
});

export default productsSlice.reducer;

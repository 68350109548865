import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api',
});

axiosInstance.interceptors.request.use(
  (config) => {
    debugger;
    console.log( localStorage.getItem('token') + " Token Data")
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
); 

/* export const API_GET_LOGIN = () => {
  return axios.get("/localhost:8011/api/users");
}; */

export const API_POST_LOGIN = (data) => {
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/login/validate", data);
}

export const API_GET_SUPPLIER= () => {
  return axiosInstance.get("/supplier");
}

export const API_POST_SUPPLIER= (data) => {
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/supplier",data);
}

export const API_POST_PRODUCT = (data) => {
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/product", data);
}

export const API_GET_PRODUCT = () => {
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/product");
}

export const API_POST_Industry = (data) => {
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/industry", data);
}

export const API_GET_INDUSTRY = () => {
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/industry");
}

export const API_GET_COMPANY = () =>{
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/company");
}

export const API_POST_COMPANY = (data) =>{
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/company",data);
}


export const API_GET_PERSON = () =>{
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/person");
}

export const API_POST_PERSON = (data) =>{
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/person",data);
}

export const API_GET_PROJECTS = () =>{
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/projects");
}

export const API_POST_PROJECTS = (data) =>{
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/projects",data);
}






export const API_GET_USER = () =>{
  const tenantId = localStorage.getItem('tenantId');
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/users/tenant/"+tenantId);
}

export const API_POST_USER = (data) =>{
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/users",data);
}


/**
 *  
 *   Leads Related Methods.  
 * 
 */


export const API_GET_LEAD = () =>{
  const tenantId = localStorage.getItem('tenantId');
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/leads/tenant/"+tenantId);
}

export const API_POST_LEAD = (data) =>{
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/leads",data);
}



/**
 * 
 * Prospect related Methods. 
 * 
 */


export const API_GET_PROSPECT = () =>{
  const tenantId = localStorage.getItem('tenantId');
  return axios.get("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/prospect/tenant/"+tenantId);
}

export const API_POST_PROSPECT = (data) =>{
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/prospect",data);
}


export const API_GET_MANAGER = () => {
  const tenantId = localStorage.getItem('tenantId');
  return axios.post("http://ec2-3-25-129-88.ap-southeast-2.compute.amazonaws.com:8011/api/users/managers/"+tenantId);
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_GET_SUPPLIER, API_POST_SUPPLIER } from '../services/CommonService';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchSuppliers = createAsyncThunk('suppliers/fetchSuppliers', async () => {
  try {
    const response = await API_GET_SUPPLIER();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch suppliers: ' + error.message);
  }
});

export const saveSuppliers = createAsyncThunk('suppliers/saveSuppliers', async (data) => {
  const response = await API_POST_SUPPLIER(data);
  return response.data;
});

const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuppliers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSuppliers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectSuppliers = (state) => state.suppliers;

export default supplierSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_GET_PROJECTS, API_POST_PROJECTS } from '../services/CommonService';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchProjects = createAsyncThunk('users/fetchProjects', async () => {
  try {
    const response = await API_GET_PROJECTS();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch projects: ' + error.message);
  }
});

export const saveProjects = createAsyncThunk('users/fetchProjects', async (data) => {
  const response = await API_POST_PROJECTS(data);
  return response.data;
});

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectProjects = (state) => state.projects;

export default projectSlice.reducer;

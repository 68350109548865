import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_GET_USER, API_POST_USER } from '../services/CommonService';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  try {
    const response = await API_GET_USER();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users: ' + error.message);
  }
});

export const saveUsers = createAsyncThunk('users/saveUsers', async (data) => {
  const response = await API_POST_USER(data);
  return response.data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectUsers = (state) => state.users;

export default userSlice.reducer;



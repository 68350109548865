import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  API_POST_Industry , API_GET_INDUSTRY} from '../services/CommonService'; //API_GET_LOGIN,

export const addNewIndustry = createAsyncThunk('industry/addIndustry', async (newIndustry) => {
  const response = await API_POST_Industry(newIndustry);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error('Failed to add product');
  }
});

export const fetchIndustryOptions = createAsyncThunk('industry/fetchIndustryOptions', async () => {
  const response = await API_GET_INDUSTRY();
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error('Failed to fetch industry options');
  }
});

const industrySlice = createSlice({
  name: 'industry',
  initialState: {
    industryOptions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(addNewIndustry.fulfilled, (state, action) => {
        state.industryOptions.push(action.payload);
      })
      .addCase(fetchIndustryOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      });
  },
});

export default industrySlice.reducer;

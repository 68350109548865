import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_GET_PROSPECT, API_POST_PROSPECT} from '../services/CommonService';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchProspects = createAsyncThunk('prospects/fetchProspects', async () => {
  try {
    const response = await API_GET_PROSPECT();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch users: ' + error.message);
  }
});

export const saveProspects = createAsyncThunk('prospects/saveProspects', async (data) => {
  const response = await API_POST_PROSPECT(data);
  return response.data;
});

const prospectsSlice = createSlice({
  name: 'prospects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProspects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProspects.fulfilled, (state, action) => {
        debugger;
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchProspects.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectProspects = (state) => state.prospects;

export default prospectsSlice.reducer;



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_GET_PERSON, API_POST_PERSON } from '../services/CommonService';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchPersons = createAsyncThunk('users/fetchPersons', async () => {
  try {
    const response = await API_GET_PERSON();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch persons: ' + error.message);
  }
});

export const savePersons = createAsyncThunk('users/savePersons', async (data) => {
  const response = await API_POST_PERSON(data);
  return response.data;
});

const personSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPersons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPersons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectPersons = (state) => state.persons;

export default personSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  API_POST_LOGIN } from '../services/CommonService'; //API_GET_LOGIN,

export const login = createAsyncThunk('auth/login', async (data) => {
  const response = await API_POST_LOGIN(data);
  //const token = response.data.token;
  const { token, ...userData } = response.data
  localStorage.setItem('token', token);
  return { token, ...userData };
});

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    userData: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default loginSlice.reducer;

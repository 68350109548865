    import React, {Suspense} from 'react';
    import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
    const LoginPanel = React.lazy(() => import("../component/users/Login"));
    const Milaniya = React.lazy(() => import("../component/Milaniya/Milaniya"))
    const SoftwareJunction = React.lazy(() => import("../component/Milaniya/SoftwareJunction"))
    const ComingSoon = React.lazy(() => import("../component/Milaniya/ComingSoon"))
    const Dashboard = React.lazy(() => import("../component/Dashboard/Dashboard"))

    const OEMindDB = React.lazy(() =>import("../component/OEMind/OEMindDB"))
    const AccessDenied = React.lazy(() =>import("../component/Dashboard/AccessDenied"))
    //dashboard

    function AppRoutes() {

        const isAuthenticated = () => {
            debugger;
            return !!localStorage.getItem('token'); 
          };
          
          const ProtectedRoute = ({ element }) => {
            return isAuthenticated() ? element : <Navigate to="/access-denied" replace />;
          };
    
    return (
        <Router>
        <Suspense fallback={<div></div>}>
        
            <div>
            <Routes>
                <Route exact path="" element={<Milaniya />} />
                <Route exact path="softwareJunction" element={<SoftwareJunction />} />
                <Route exact path="login" element={<LoginPanel />} />
         

               <Route exact path ="dashboard"  element={<ProtectedRoute element={<Dashboard/>} />} />

               


                <Route exact path ="oeminddb" element={<OEMindDB />} /> 

                <Route exact path ="comingsoon" element={<ComingSoon />} />
               
                <Route path="/access-denied" element={<AccessDenied />} />

                <Route path="*" element={<Navigate to={{ pathname: "/" }} />} />
            </Routes>
            
            </div>
        </Suspense>
        </Router>
    );
    }

    export default AppRoutes;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_GET_COMPANY, API_POST_COMPANY } from '../services/CommonService';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchCompaines = createAsyncThunk('compnies/fetchCompanies', async () => {
  try {
    const response = await API_GET_COMPANY();
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch compnies: ' + error.message);
  }
});

export const saveCompanies = createAsyncThunk('compnies/saveCompanies', async (data) => {
  const response = await API_POST_COMPANY(data);
  return response.data;
});

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaines.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompaines.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCompaines.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectCompnaies = (state) => state.companies;

export default companySlice.reducer;
import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../reducer/LoginSlicer';
import supplierReducer from '../reducer/SupplierSlicer';
import productSlicer from '../reducer/ProductSlicer';
import IndustrySlicer from '../reducer/IndustrySlicer';
import UserSlicer from '../reducer/UserSlicer';
import CompanySlicer from '../reducer/CompanySlicer';
import ProjectSlicer from '../reducer/ProjectSlicer';
import PersonSlicer from '../reducer/PersonSlicer';
import LeadSlicer from '../reducer/LeadSlicer';
import ProspectSlicer from '../reducer/ProspectSlicer';

const store = configureStore({
  reducer: {
    login: loginReducer,
    suppliers: supplierReducer,
    product : productSlicer,
    industry : IndustrySlicer,
    users : UserSlicer,
    companies : CompanySlicer,
    persons : PersonSlicer,
    project : ProjectSlicer,
    leads : LeadSlicer,
    prospects : ProspectSlicer
  },
});

export default store;